import React, { createContext, useState } from "react";
import { useSelector } from "react-redux";
import usePredictiveText from "../Slides/Hooks/usePredictiveText";

// Context for the form
export const FormContext = createContext();

// Provider for the form context
export const FormContextProvider = ({ children }) => {
  const clientData = useSelector((state) => state.client);
  const [disableAll, setDisableAll] = useState(false);
  const [timeStamp, setTimeStamp] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState("Not Started");
  const [activeUserId, setActiveUserId] = useState(null);
  const [openCheckModal, setOpenCheckModal] = useState(false);
  const [isReady, setIsReady] = useState(false);

  // Predictive Text Query
  const {
    data: {
      names,
      companies,
      titles,
      emails,
      phoneNumbers,
      regulatoryAgencies,
    } = {},
    isFetching: predictiveTextIsFetching,
    isFetched: predictiveTextFetched,
  } = usePredictiveText(clientData.id);

  // Form State Values for each Slide
  const [slide1, setSlide1] = useState({
    projectName: "",
    pointOfContact: "",
    pointOfContactPhone: "",
    pointOfContactEmail: "",
    pointOfContactTitle: "",
    onNativeLand: false,
    jobNumber: "",
    underAcre: false,
    accountingContact: "",
    accountingEmail: "",
  });

  const [slide2, setSlide2] = useState({
    swpppServicesRequest: true,
    sNum: "1",
    submittingToRegAgency: false,
    regAgencySubmitDate: "",
    activeMyDeq: false,
    activeCDX: false,
    cdxAccountManager: "",
    cdxUserId: "",
    noiRequest: false,
    inspectionRequest: false,
    signRequest: false,
    sign3x3: false,
    signStand: false,
    inspectionRecipient1: "",
    inspectionRecipient1Title: "",
    inspectionRecipient1Email: "",
    inspectionRecipient2: "",
    inspectionRecipient2Title: "",
    inspectionRecipient2Email: "",
    inspectionRecipient3: "",
    inspectionRecipient3Title: "",
    inspectionRecipient3Email: "",
    inspectionRecipient4: "",
    inspectionRecipient4Title: "",
    inspectionRecipient4Email: "",
    inspectingCompany: "",
    inspectorName: "",
    inspectorEmail: "",
    inspectorPhone: "",
  });

  const [slide3, setSlide3] = useState({
    t1: false,
    t2: false,
    t3: false,
    t4: false,
    t5: false,
    t6: false,
    t7: false,
    t8: false,
    t9: false,
    t10: false,
    t11: false,
    t12: false,
    t13: false,
    other: false,
    otherText: "",
    scopeOfWork: "",
  });

  const [slide4, setSlide4] = useState({
    startDate: "",
    endDate: "",
    duration: "",
    emergencyContactName: "",
    emergencyContactPhone: "",
    emergencyContactEmail: "",
    emergencyContactTitle: "",
    emergencyContactCompany: "",
    prevAgricultureLand: false,
    demolitionActivities: false,
    dewateringDischarge: false,
    totAcr: "",
    distAcr: "",
    layDownYard: false,
    layDownYardDist: false,
    borrowArea: false,
    portableTanksOnSite: false,
    portableTankCapacity: "",
    bmpCompanyName: "",
    bmpContactName: "",
    bmpContactPhone: "",
    bmpContactEmail: "",
  });

  const [slide5, setSlide5] = useState({
    pAdd: "",
    pCity: "",
    pZip: "",
    pCounty: "",
    bLat: "",
    bLng: "",
    eLat: "",
    eLng: "",
    isLinear: false,
    marker: { position: [0, 0], details: ["", "", "", ""] },
    marker2: { position: [0, 0], details: ["", "", "", ""] },
  });

  const [slide6, setSlide6] = useState({
    oName: "",
    oPhone: "",
    oAdd: "",
    oCity: "",
    oState: "",
    oZip: "",
    oContactName: "",
    oContactPhone: "",
    oContactEmail: "",
    oContactTitle: "",
    ownerGCCheck: false,
    delegatedRepCheck: false,
    odrCompany: "",
    odrName: "",
    odrPhone: "",
    odrEmail: "",
    odrTitle: "",
    wsNumber: "",
    waterServicesContact: "",
    waterServicesPhone: "",
    waterServicesEmail: "",
  });

  const [slide7, setSlide7] = useState({
    gcName: "",
    gcAdd: "",
    gcCity: "",
    gcState: "",
    gcZip: "",
    gcPhone: "",
    gcContactName: "",
    gcContactTitle: "",
    gcContactPhone: "",
    gcContactEmail: "",
    gcContact2Name: "",
    gcContact2Title: "",
    gcContact2Phone: "",
    gcContact2Email: "",
    gcContact3Name: "",
    gcContact3Title: "",
    gcContact3Phone: "",
    gcContact3Email: "",
    projectManager: "",
    projectManagerPhone: "",
    projectManagerEmail: "",
    superintendent: "",
    superintendentPhone: "",
    superintendentEmail: "",
  });

  const [slide8, setSlide8] = useState({
    dustControlRequest: true,
    manualLogsRequest: false,
    dustAuditRequest: false,
    dustSignRequest: false,
    dcName: "",
    dcPhone: "",
    dcc: "",
    dccPhone: "",
    dccEmail: "",
    dccTitle: "",
    dustAuditContact: "",
    dustAuditContactPhone: "",
    dustAuditContactEmail: "",
  });

  const [slide9, setSlide9] = useState({
    level1Safety: false,
    level2Safety: false,
  });

  const [slide10, setSlide10] = useState({
    dustPermitNumber: "",
    dustPermit: {
      name: "dustPermit",
      files: [],
      status: "UC",
    },
    inspectorQualifications: {
      name: "inspectorQualifications",
      files: [],
      status: "UC",
    },
    swmpPlan: {
      name: "swmpPlan",
      files: [],
      status: "UC",
    },
    landscapePlan: {
      name: "landscapePlan",
      files: [],
      status: "UC",
    },
    gradingPlan: {
      name: "gradingPlan",
      files: [],
      status: "UC",
    },
    drainageReport: {
      name: "drainageReport",
      files: [],
      status: "UC",
    },
    soilsReport: {
      name: "soilsReport",
      files: [],
      status: "UC",
    },
    environmentalReport: {
      name: "environmentalReport",
      files: [],
      status: "UC",
    },
    endangeredSpeciesReport: {
      name: "endangeredSpeciesReport",
      files: [],
      status: "UC",
    },
    archeologicalReport: {
      name: "archeologicalReport",
      files: [],
      status: "UC",
    },
    corpOfEngineersReport: {
      name: "corpOfEngineersReport",
      files: [],
      status: "UC",
    },
    constructionSchedule: {
      name: "constructionSchedule",
      files: [],
      status: "UC",
    },
    projectSpecifications: {
      name: "projectSpecifications",
      files: [],
      status: "UC",
    },
    listOfSubcontractors: {
      name: "listOfSubcontractors",
      files: [],
      status: "UC",
    },
  });

  const [slide11, setSlide11] = useState({
    additionalInformation: "",
    submitter: "",
    submitterPhone: "",
    submitterEmail: "",
    submitterTitle: "",
  });

  // Scroll to the input field and open the helper text
  const handleInputFocus = (ref, setOpenHelper) => {
    ref.current.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });

    setOpenHelper(() => true);
  };

  // Disable input fields based on the request status and current userId/data.activeUserId
  const handleInputDisable = (idProp, state, disabledInput, userId) => {
    if (disableAll === true) return true;
    if (requestStatus === "P") return true;
    if (requestStatus === "IP" && activeUserId === userId) {
      return false;
    }
    if (requestStatus === "IP" && activeUserId !== userId) {
      return true;
    }
    if (requestStatus === "RV" && disabledInput === true) {
      return true;
    }
    return false;
  };

  // Get the invalid data for the input field
  function getInvalidData(inputName, invalidInputsData, slideNumber) {
    /*
    inputName: string (key of slides state)
    invalidInputsData: object (invalid inputs data) {data:[{name, message, slide, initialValue}]}
    slideNumber: string (slide number 1-10)
    */
    if (!invalidInputsData.data) return undefined;
    let invalidData = invalidInputsData.data.filter((obj) => {
      if (obj.slide === slideNumber && obj.name === inputName) {
        return obj;
      }
    })[0];

    return invalidData === undefined ? undefined : invalidData;
  }

  // Populate the invalid inputs for the slide
  const populateInvalidInputs = (
    invalidInputsData,
    disabledInputs,
    setDisabledInputs,
    slideNumber,
  ) => {
    if (
      requestStatus === "RV" &&
      invalidInputsData.isFetched &&
      invalidInputsData.data
    ) {
      let slidesInvalid = invalidInputsData.data.filter((obj) => {
        return obj.slide === slideNumber;
      });
      if (
        slidesInvalid.length > 0 &&
        !disabledInputs.includes(slidesInvalid[0].name)
      ) {
        slidesInvalid = slidesInvalid.map((obj) => obj.name);
        setDisabledInputs(() => [...slidesInvalid]);
      }
    }
  };

  // Gets the invalid input message
  const getInputMessage = (inputName, invalidInputsData, slideNumber) => {
    let message = "";
    if (invalidInputsData.isFetched && invalidInputsData.data) {
      let slidesInvalid = invalidInputsData.data.filter((obj) => {
        if (obj.slide === slideNumber && obj.name === inputName) {
          return obj;
        }
      });
      if (slidesInvalid.length > 0) {
        message = slidesInvalid[0].message;
      }
    }
    return message;
  };

  // Checks if an input should be disabled
  const disabledInputCheck = (inputName, disabledInputs) => {
    let disabled = true;
    if (disabledInputs.includes(inputName)) {
      disabled = false;
    }
    return disabled;
  };

  return (
    <FormContext.Provider
      value={{
        slide1,
        setSlide1,
        slide2,
        setSlide2,
        slide3,
        setSlide3,
        slide4,
        setSlide4,
        slide5,
        setSlide5,
        slide6,
        setSlide6,
        slide7,
        setSlide7,
        slide8,
        setSlide8,
        slide9,
        setSlide9,
        slide10,
        setSlide10,
        slide11,
        setSlide11,
        timeStamp,
        setTimeStamp,
        pageNumber,
        setPageNumber,
        isLoading,
        setIsLoading,
        names,
        companies,
        titles,
        emails,
        phoneNumbers,
        regulatoryAgencies,
        predictiveTextIsFetching,
        predictiveTextFetched,
        clientData,
        disableAll,
        setDisableAll,
        requestStatus,
        setRequestStatus,
        activeUserId,
        setActiveUserId,
        handleInputFocus,
        handleInputDisable,
        getInvalidData,
        populateInvalidInputs,
        getInputMessage,
        disabledInputCheck,
        openCheckModal,
        setOpenCheckModal,
        isReady,
        setIsReady,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
