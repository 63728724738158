// Rewrite each message so that all letters are lowercase except for the first letter of the first word in each sentence.
// | SLIDE 1 |
export const projectNameMessage =
  "The official name of the project as shown on the civil plans";
export const pointOfContactMessage =
  "The name of the individual who will be the main point of contact for this project";
export const pointOfContactTitleMessage =
  "The working title of the main point of contact";
export const pointOfContactPhoneMessage =
  "The phone number of the main point of contact";
export const pointOfContactEmailMessage =
  "The email address of the main point of contact";
export const onNativeLandMessage =
  "Is the project located onTribal or Native land?";

export const underAcreMessage =
  "Is the size of the project, including construction yards, under 1 acre?";
export const jobNumberMessage =
  "The purchase order or job number for billing this project";

export const accountingContactMessage =
  "The name of the individual who will be the main point of contact for billing this project";
export const accountingEmailMessage =
  "The email address associated with billing for this project";

// | Slide 2 |
export const swpppServicesRequestMessage =
  "These options include: SWPPP preparation, NOI preparation/submission, inspection services, and SWPPP signage";
export const sNumMessage = "Requested number of manuals";
export const submittingToRegAgencyMessage =
  "Will the SWPPP be submitted to a regulatory agency? (EPA, Municipality, etc.)";
export const regAgencySubmitDateMessage =
  "Date of when you will be submitting to the regulatory agency";
export const activeMyDeqMessage =
  "Is there an active account associated with this project for permitting?";

export const activeCDXMessage =
  "Is there an active account associated with this project for permitting?";

export const cdxAccountManagerMessage = "The name of the account manager";

export const cdxUserId = "The user ID for the account";
export const noiRequestMessage =
  "Would you like Argus to prepare your NOI application?";
export const inspectionRequestMessage =
  "Will Argus be performing inspections for this project?";
export const inspectionRecipient1Message =
  "The name of the individual that will be receiving the SWPPP inspection reports";
export const inspectionRecipient1TitleMessage =
  "The working title of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient1EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient2Message =
  "The name of the individual that will be receiving the SWPPP inspection reports";
export const inspectionRecipient2TitleMessage =
  "The working title of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient2EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection reports";

export const inspectionRecipient3Message =
  "The name of the individual that will be receiving the SWPPP inspection reports";
export const inspectionRecipient3TitleMessage =
  "The working title of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient3EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient4Message =
  "The name of the individual that will be receiving the SWPPP inspection reports";
export const inspectionRecipient4TitleMessage =
  "The working title of the individual who will be receiving the SWPPP inspection reports";
export const inspectionRecipient4EmailMessage =
  "The email address of the individual who will be receiving the SWPPP inspection reports";
export const inspectingCompanyMessage =
  "The name of the company that will be performing the SWPPP inspections. If Argus is to perform the inspections, please check 'YES' on the previous question";
export const inspectorNameMessage =
  "The name of the individual that will be performing the SWPPP inspections";
export const inspectorPhoneMessage =
  "The phone number of the individual that will be performing the SWPPP inspections";
export const inspectorEmailMessage =
  "The email address of the individual that will be performing the SWPPP inspections";
export const signRequestMessage =
  "Would you like Argus to provide a SWPPP sign";
export const sign3x3Message = "3'x3' SWPPP sign";
export const signStandMessage = "3'x3' SWPPP sign & stand";

// | SLIDE 3 |
export const t1Message =
  "Project is being directed by the Arizona Department of Transportation";
export const t2Message =
  "Project involves construction of airport related facilities";
export const t3Message =
  "Project involves construction of business properties and complexes";
export const t4Message =
  "Project involves construction of flood related management";
export const t5Message = "Project consists of sand and gravel operations";
export const t6Message =
  "Project consists of community facilities, and government buildings";
export const t7Message =
  "Project consists of installation, maintenance, and repair of water and gas lines";
export const t8Message = "Project consists of recreational facilities";
export const t9Message = "Project consists of private residential homes";
export const t10Message =
  "Project consists of construction and maintenance of streets, roadways, and highways";
export const t11Message =
  "Project consists of construction or renovation of educational campuses";
export const t12Message =
  "Project consists of single-family homes or apartment complexes";
export const t13Message =
  "Project consists of electricity, water, and sewer improvements";
export const otherMessage = "Another type of project not listed";
export const otherTextMessage =
  "If this project's development type is not located in the list above, please specify the correct project type";
export const scopeOfWorkMessage =
  "Please select or add the scope of work to be performed on this project";

// | SLIDE 4 |
export const startDateMessage =
  "The date the project's construction activities are scheduled to start";
export const endDateMessage =
  "The estimated date this project is to complete construction activities";
export const emergencyContactNameMessage =
  "The name of the individual to contact in case of an emergency";
export const emergencyContactCompanyMessage =
  "The company of the emergency contact";
export const emergencyContactPhoneMessage =
  "The phone number of the emergency contact";
export const emergencyContactEmailMessage =
  "The email address of the emergency contact";
export const emergencyContactTitleMessage =
  "The working title of the emergency contact";
export const totAcrMessage =
  "The total acreage of the project, including construction yard";
export const distAcrMessage =
  "The total acreage to be disturbed, including construction yard";
export const layDownYardMessage =
  "Is there a construction/laydown yard associated with this project?";
export const layDownYardDistMessage =
  "Is the laydown yard/area included on the disturbed acreage listed above?";
export const borrowAreaMessage =
  "Is there an onsite plant or borrow area exclusively for this project?";
export const portableTanksOnSiteMessage =
  "Will there be portable fuel storage tanks onsite?";
export const portableTankCapacityMessage =
  "The total capacity of the portable fuel storage tanks. If more than 1,320 combined gallons, a SPCC Plan may be required";

export const prevAgricultureLand =
  "Was the project site previously used for agricultural purposes?";

export const demolitionActivitiesMessage =
  "Will there be demolition activities on this project?";

export const dewateringDischargeMessage =
  "Will there be dewatering discharge activities associated with this project?";
export const bmpCompanyNameMessage =
  "The name of the company that will be installing and maintaining the control measures";
export const bmpContactNameMessage =
  "The main point of contact for the company installing and maintaining the control measures";
export const bmpContactPhoneMessage =
  "The phone number of the main point of contact";
export const bmpContactEmailMessage =
  "The email address of the main point of contact";

// | SLIDE 5 |
export const isLinearMessage =
  "Is the project linear (e.g public/private roadways, utilities, etc.)?";
export const pAddMessage =
  "Add the exact street address or the nearest cross streets for this project, if the map above did not accurately do so. (e.g SWC of 7th Ave. & Olive, 123 Main St., etc.)";
export const pCityMessage = "The name of city where the project is located";
export const pCountyMessage =
  "The name of the county where the project is located";
export const pZipMessage = "The zip code for this project";
export const latMessage =
  "The closest latitude point of the center of the project. You can find this value on Google Maps by right clicking on the map and then selecting the coordinates option. This will copy the coordinates to your clipboard. The latitude value is the first number listed";
export const lngMessage =
  "The closest longitude point of the center of the project. You can find this value on Google Maps by right clicking on the map and then selecting the coordinates option. This will copy the coordinates to your clipboard. The longitude value is the second number listed";

//| SLIDE 6 |
export const oNameMessage = "The name owning entity of the project";
export const oAddMessage = "The owning entities office address";
export const oCityMessage =
  "The city where the owning entities office is located";
export const oZipMessage = "The Zip Code for the owning entities office";

export const oStateMessage =
  "The state where the owning entities office is located";
export const oPhoneMessage = "The owning entities office phone number";
export const oContactNameMessage =
  "The name of the individual who has the authority to make legal decisions on behalf of the company";
export const oContactTitleMessage =
  "The working title of the individual who has the authority to make legal decisions on behalf of the company";
export const oContactPhoneMessage = "The responsible officer's phone number";
export const oContactEmailMessage = "The responsible officer's email address";
export const ownerGCCheckMessage =
  "Is the owning entity also the primary operator/general contractor for this project?";
export const delegatedRepCheckMessage =
  "Is the responsible officer delegating responsibility to another individual to act on their behalf?";
export const odrCompanyMessage =
  "The name of the company that the delegated representative is associated with";
export const odrNameMessage =
  "The name of the individual who is delegated responsibility to act on behalf of the company and responsible officer";
export const odrTitleMessage =
  "The working title of the delegate representative";
export const odrPhoneMessage =
  "The phone number of the delegated representative";
export const odrEmailMessage =
  "The email address of the delegated representative";

export const wsNumberMessage = "The water services number for this project";
export const waterServicesContactMessage =
  "The name of the water services department contact";
export const waterServicesPhoneMessage =
  "The phone number of the water services department contact";
export const waterServicesEmailMessage =
  "The email address of the water services department contact";

// Rewrite the following messages exactly as they are written, but only capitalize the first word in each sentence.
// | SLIDE 7 |
export const gcNameMessage =
  "The name of the primary operator/general contractor for this project";
export const gcAddMessage = "The operator's office address";
export const gcCityMessage = "The city where the operator's office is located";
export const gcZipMessage = "The zip code for the operator's office address";

export const gcStateMessage =
  "The state where the operator's office is located";
export const gcPhoneMessage = "Operator's office phone number";
export const gcContactNameMessage =
  "The name of the individual who has the authority to make legal decisions on behalf of the company and will be the individual certifying the Notice of Intent (NOI)";
export const gcContactTitleMessage =
  "The working title of the responsible officer";
export const gcContactPhoneMessage = "Responsible officer's phone number";
export const gcContactEmailMessage = "Responsible officer's email address";
export const gcContact2NameMessage =
  "Person in charge of day-to-day operations for this project and has been delegated authority to act on behalf of the responsible officer";
export const gcContact2TitleMessage =
  "The working title of the day-to-day operations contact";
export const gcContact2PhoneMessage =
  "The phone number of the day-to-day operations contact";
export const gcContact2EmailMessage =
  "The email address of the day-to-day operations contact";
export const gcContact3NameMessage =
  "The name of the individual who will be in charge of this project's field operations";
export const gcContact3TitleMessage = "The working title of the field operator";
export const gcContact3PhoneMessage = "The phone number of the field operator";
export const gcContact3EmailMessage = "The email address of the field operator";

export const projectManagerMessage = "The name of the project manager";

export const projectManagerPhoneMessage =
  "The phone number of the project manager";

export const projectManagerEmailMessage =
  "The email address of the project manager";

export const superintendentMessage = "The name of the project superintendent";

export const superintendentPhoneMessage =
  "The phone number of the project superintendent";

export const superintendentEmailMessage =
  "The email address of the project superintendent";

// | SLIDE 8 |
export const dustControlRequestMessage =
  "These options include: Dust Control Manual & Logs, Dust Control Audits, and Dust Control Signage (Projects disturbing 5 or more acres)";
export const manualLogsRequestMessage =
  "Would you like Argus to provide a dust constrol manual?";
export const dccMessage =
  "The name of the individual who will be responsible for dust control on the project";
export const dccTitleMessage =
  "The working title of the project's dust control coordinator"; // corrected spelling
export const dccPhoneMessage =
  "The phone number of the dust control coordinator"; // corrected capitalization
export const dccEmailMessage =
  "The email address of the dust control coordinator"; // corrected capitalization
export const dustAuditRequestMessage =
  "Would you like Argus to provide dust control audits?"; // corrected wording
export const dustAuditContactMessage =
  "The name of the individual who will be receiving the dust control audit reports"; // corrected capitalization
export const dustAuditContactEmailMessage =
  "The email address of the individual who will be receiving the dust control audit reports"; // corrected capitalization
export const dustSignRequestMessage =
  "Would you like Argus to provide a dust control sign"; // corrected capitalization
export const dcNameMessage =
  "The name of the individual whose name will be on the dust control sign";
export const dcPhoneMessage =
  "The phone number of the individual whose name will be on the dust control sign"; // corrected capitalization
export const dcEmailMessage =
  "The email address of the individual whose name will be on the dust control sign"; // corrected capitalization
export const dustPermitNumberMessage =
  "This project's dust control permit number, if available"; // corrected possessive

// | SLIDE 9 |
export const level1SafetyMessage =
  "Would you like Argus to conduct Level 1 safety inspections?";
export const level2SafetyMessage =
  "Would you like Argus to conduct Level 2 safety inspections?";

// | SLIDE 10 |
export const dustPermitMessage =
  "Upload a copy of the dust control permit if applicable";
export const inspectorQualificationsMessage =
  "If Argus will not be performing this project's SWPPP inspections, please upload a copy of the inspector's qualifications"; // corrected possessive
export const swmpPlanMessage =
  "Upload the Stormwater Management Plan (SWMP) or Stormwater Pollution Prevention Plan (SWPPP)";
export const landscapePlanMessage =
  "Upload the landscape plan for this project";
export const gradingPlanMessage =
  "Upload the grading & drainage plans for this project";
export const drainageReportMessage =
  "Upload the drainage report for this project";
export const soilsReportMessage =
  "Upload the geotechnical/soils report for this project";
export const environmentalReportMessage =
  "Upload the Phase 1 or 2 environmental report for this project";
export const endangeredSpeciesReportMessage =
  "Upload the biological or endangered species report for this project";
export const archeologicalReportMessage =
  "Upload the archaeological report for this project";
export const corpOfEngineersReportMessage =
  "Upload the Army Corps of Engineers permit for this project";
export const constructionScheduleMessage =
  "Upload the construction schedule for this project";
export const projectSpecificationsMessage =
  "Upload the project specifications for this project";
export const listOfSubcontractorsMessage =
  "Upload a list of subcontractors for this project";

// | SLIDE 11 |
export const submitterMessage =
  "Your name will be used at the submitter/contact for this form, only if you successfully submit this form (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterTitleMessage =
  "Your official working title. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterPhoneMessage =
  "Your phone number. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
export const submitterEmailMessage =
  "Your email address. (Your information is accessed through your account, and can be changed at any time by accessing your account settings in the sidebar menu)";
